<template>
  <va-card>
    <va-card-title>
      <slot name="title">
        {{ props.title }}
      </slot>
    </va-card-title>
    <va-card-content>
      <table class="kv-table">
        <tr v-if="props.rows.length === 0">
          <td class="no-data" colspan="2">{{ noDataMessage }}</td>
        </tr>
        <tr
          v-for="(row, index) in props.rows"
          :key="`row-${i18nPrefix}-${index}}`"
        >
          <td>
            <label>{{ row.label }}</label>
          </td>
          <slot v-if="!editMode || !row.editable" :name="`${row.label}-value`">
            <td>{{ row.value || "/" }}</td>
          </slot>
          <slot v-else :name="`${row.label}-edit-value`" />
        </tr>
      </table>
    </va-card-content>
  </va-card>
</template>

<script setup>
const props = defineProps({
  title: {
    type: String,
    required: true
  },
  i18nPrefix: {
    type: String,
    required: true
  },
  // [{label, value, editable}]
  rows: {
    type: Array,
    required: true
  },
  editMode: {
    type: Boolean,
    default: false
  },
  noDataMessage: {
    type: String,
    default: "No Data to display"
  }
});
</script>

<style scoped>
.kv-table label:after {
  content: ":";
}

.kv-table td {
  padding: 0.5rem;
}

.kv-table label {
  font-size: 0.9em;
  font-weight: 700;
  color: var(--va-gray);
  text-transform: uppercase;
}
.no-data {
  padding: 1rem;
  text-align: center;
  font-weight: bold;
  color: #7a7979;
}
</style>
